<!-- TEMPLATE -->
<template>
    <!-- SECTION TRAILER + ABOUT -->
    <section id="wishlist" class="wishlist py-5">
        <div class="container">
            <div class="row">

                <!-- TEXT DESK -->
                <div class="col-12 col-xl-6 d-flex justify-content-center align-items-center">
                    <div class="col-12 text-center">

                        <div class="py-2" v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="100" >
                            <!-- DESK -->
                            <h2 class="wishlist__text pinkneontxt fs-1 fw-bold d-none d-md-block">WISHLIST NOW ON STEAM!</h2>
                            <!-- MOBILE -->
                            <h4 class="wishlist__text pinkneontxt fs-1 fw-bold d-block d-md-none">WISHLIST NOW ON STEAM!</h4>
                        </div>

                        <div class="joindiscord pinkglowloop pt-2" v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="200">
                            <a href="https://discord.gg/e8NvKhXqrU">
                                <!-- DESK -->
                                <div class="pinkneontxt d-none d-md-block">
                                    <span class="wishlist__discord fs-1 fa-brands fa-discord mx-2"></span>
                                    <h2 class="wishlist__text2 fs-1 fw-bold">JOIN OUR DISCORD</h2>
                                </div>
                                <!-- MOBILE -->
                                <div class="pinkneontxt d-block d-md-none">
                                    <span class="wishlist__discord fs-1 fa-brands fa-discord fs-4 mx-2"></span>
                                    <h4 class="wishlist__text2 fs-1 fw-bold">JOIN OUR DISCORD</h4>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>

                <!-- STEAM WIDGET -->
                <div class="col-12 col-xl-6 d-flex justify-content-center align-items-center pt-5 pt-xl-0"
                v-motion :initial="{ opacity: 0, x: 100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="300" 
                >
                    <iframe src="https://store.steampowered.com/widget/2902170/" frameborder="0" width="646" height="200"></iframe>
                </div>

            </div>
        </div>
    </section>
</template>

<!-- SCRIPT -->
<script>
export default {
    name: 'WishlistComponent',
    // props: {},
    data: function(){
        return{}
    },
    // computed: {},
    // methods: {},
    // watch: {},
    // components: {},
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<!-- STYLE -->
<style lang="scss" scoped>

section{
    width: 100vw;
}

.wishlist{
    background: url(../assets/img/bg/jpg/bgdiv.jpg) rgba(var(--color3rgba, 0.1));
    background-size: cover; 
    background-blend-mode: multiply;
    background-position: center;
    background-repeat: no-repeat;
    filter: drop-shadow(0px 0px 12px rgba(20, 14, 22, 0.9));

    &__text{
        font-family: var(--ff2);
        font-size: var(--h2);
        transform: scale(0.94,1.5);
        letter-spacing: .2rem;
    }
    &__text2{
        display: inline-block;
        font-family: var(--ff2);
        font-size: var(--h2);
        transform: scale(0.94,1.3);
        letter-spacing: .2rem;
    }
    &__discord{
        font-size: var(--h2);
    }
}

.joindiscord{
    transition: 0.3s linear;
    color: var(--color5);

    &:hover{
        cursor:pointer;
        text-shadow: rgba(219, 25, 171, 1) 0px 0px 8px;
    }
}

.pinkglowloop{
    animation: glowing 3800ms infinite;
}

@keyframes glowing {
  0% { filter: drop-shadow(0px 0px 18px rgba(240, 16, 203, .4)); }
  50% { filter: drop-shadow(0px 0px 18px rgba(240, 16, 203, 1)); }
  100% { filter: drop-shadow(0px 0px 18px rgba(240, 16, 203, .4)); }
}

</style>