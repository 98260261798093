<!-- TEMPLATE -->
<template>
    <!-- SECTION TRAILER + ABOUT -->
    <section id="divider" class="divider py-5">
        <div class="container">
            <!-- TEXT DESK -->
            <div class="col-12">
                <div class="col-12 text-center">

                    <div class="py-2" v-motion
                        :initial="{ opacity: 0, x: -100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="100">
                        <!-- DESK -->
                        <h2 class="divider__text pinkneontxt fs-1 fw-bold d-none d-md-block">MEET YOUR SQUAD</h2>
                        <!-- MOBILE -->
                        <h3 class="divider__text pinkneontxt fw-bold d-block d-md-none">MEET YOUR SQUAD</h3>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</template>

<!-- SCRIPT -->
<script>
export default {
    name: 'DividerSquadComponent',
    // props: {},
    data: function () {
        return {}
    },
    // computed: {},
    // methods: {},
    // watch: {},
    // components: {},
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<!-- STYLE -->
<style lang="scss" scoped>
section {
    width: 100vw;

}

.divider {
    background: url(../assets/img/bg/jpg/bgdiv.jpg) rgba(var(--color3rgba, 0.1));
    background-size: cover;
    background-blend-mode: multiply;
    background-position: center;
    background-repeat: no-repeat;
    filter: drop-shadow(0px 0px 12px rgba(20, 14, 22, 0.9));

    &__text{
        font-family: var(--ff2);
        font-size: var(--h2);
        transform: scale(0.94,1.5);
        letter-spacing: .2rem;
    }
}

</style>