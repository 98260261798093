<!-- TEMPLATE -->
<template>
    <footer class="footer py-5">
        <!-- WHOLE PAGE CONTAINER -->
        <div class="container-fluid px-5 text-white">
            <!-- CONTAINER -->
            <div class="container d-flex flex-wrap align-items-center">

                <!-- LOGOS DESK -->
                <div class="footer__logo d-flex col-md-6 justify-content-evenly align-items-center d-none d-md-flex">
                    <!-- LOGO EPHEMERA -->
                    <div class="mx-2">
                        <a href="https://ephemeragames.com/">
                            <img class="img-fluid logo__brand" src="../assets/img/misc/ephemera-logo.png"
                                alt="ephemera-logo">
                        </a>
                    </div>
                    <!-- LOGO WHITE THORN -->
                    <div class="mx-2">
                        <a href="https://whitethorngames.com/">
                            <img class="img-fluid logo__brand" src="../assets/img/misc/white-thorn-logo.png"
                                alt="whitethorn-logo">
                        </a>
                    </div>
                    <!-- LOGO STEAM -->
                    <div class="mx-2">
                        <a href="https://store.steampowered.com/">
                            <img class="img-fluid logo__brand" src="../assets/img/misc/steam-logo.png" alt="steam-logo">
                        </a>
                    </div>
                </div>

                <!-- LINKS DESK -->
                <div class="col-md-2 justify-content-center text-center d-none d-md-flex pt-4">
                    <!-- <h5 class="text-white footer-title-01">Links</h5> -->
                    <ul class="list-unstyled footer-link-01 m-0">
                        <li><a class="text-white text-opacity-75"
                                href="https://store.steampowered.com/app/2902170/Kernel_Hearts/">Steam Store</a>
                        </li>
                        <li><a class="text-white text-opacity-75" href="https://discord.gg/e8NvKhXqrU">Discord</a></li>
                        <!-- <li><a class="text-white text-opacity-75"
                                href="https://link.whitethorngames.com/KernelHearts-PressKit">Press
                                Kit</a></li> -->
                    </ul>
                </div>

                <!-- TEXTO DESK < 768PX -->
                <div class="col-md-4 d-none d-md-block">
                    <!-- <h5 class="text-white footer-title-01">All rights reserved</h5> -->
                    <small class="fw-lighter text-white text-opacity-50 py-0 my-0">&copy; Ephemera Games, 2022</small>
                    <br>
                    <small class="fw-lighter text-white text-opacity-50 py-0 my-0">&copy; Whitethorn Games, 2016</small>
                    <br>
                    <small class="fw-lighter text-white text-opacity-50 py-0 my-0">&copy; 2020 Valve Corporation. Steam
                        and the Steam logo are
                        trademarks and/or registered trademarks of Valve Corporation in the U.S. and/or other
                        countries.</small>
                </div>

                <!-- -------------------------- -->
                <!-- -------------------------- -->
                <!-- -------------------------- -->

                <!-- LOGOS MOBILE -->
                <div class="footer__logo col-12 d-flex-wrap d-md-none">
                    <!-- LOGO EPHEMERA -->
                    <div class="mx-5 d-flex justify-content-center">
                        <a href="https://ephemeragames.com/">
                            <img class="img-fluid logo__brand" src="../assets/img/misc/ephemera-logo.png"
                                alt="ephemera-logo">
                        </a>
                    </div>
                    <!-- LOGO WHITE THORN -->
                    <div class="mx-5 pt-3 d-flex justify-content-center">
                        <a href="https://whitethorngames.com/">
                            <img class="img-fluid logo__brand" src="../assets/img/misc/white-thorn-logo.png"
                                alt="whitethorn-logo">
                        </a>
                    </div>
                    <!-- LOGO STEAM -->
                    <div class="mx-5 pt-4 d-flex justify-content-center">
                        <a href="https://store.steampowered.com/">
                            <img class="img-fluid logo__brand" src="../assets/img/misc/steam-logo.png" alt="steam-logo">
                        </a>
                    </div>
                </div>

                <!-- LINKS MOBILE-->
                <div class="col-12 col-md-2 justify-content-center text-center d-flex d-md-none pt-4">
                    <ul class="list-unstyled footer-link-01 m-0">
                        <li><a class="text-white text-opacity-75"
                                href="https://store.steampowered.com/app/2902170/Kernel_Hearts/">Steam Store</a>
                        </li>
                        <li><a class="text-white text-opacity-75" href="https://discord.gg/e8NvKhXqrU">Discord</a></li>
                        <li><a class="text-white text-opacity-75"
                                href="https://link.whitethorngames.com/KernelHearts-PressKit">Press
                                Kit</a></li>
                    </ul>
                </div>

                <!-- TEXTO MOBILE > 768PX -->
                <div class="col-12 col-md-4 d-block d-md-none text-center pt-4">
                    <small class="fw-lighter text-white text-opacity-50 py-0 my-0">&copy; 2022 Ephemera Games.</small>
                    <br>
                    <small class="fw-lighter text-white text-opacity-50 py-0 my-0">&copy; 2016 Whitethorn Games.</small>
                    <br>
                    <small class="fw-lighter text-white text-opacity-50 py-0 my-0">&copy; 2020 Valve Corporation. Steam
                        and the Steam logo are
                        trademarks and/or registered trademarks of Valve Corporation in the U.S. and/or other
                        countries.</small>
                </div>

            </div>
        </div>
    </footer>
</template>

<!-- SCRIPT -->
<script>
export default {
    name: 'FooterComponent',
    // props: {},
    data: function () {
        return {}
    },
    // computed: {},
    // methods: {},
    // watch: {},
    // components: {},
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<!-- STYLE -->
<style lang="scss" scoped>
.footer {
    background: url(../assets/img/bg/jpg/bgdiv.jpg) rgba(var(--color3rgba, 0.1));
    background-size: cover;
    background-blend-mode: multiply;
    background-position: center;
    background-repeat: no-repeat;
    filter: drop-shadow(0px 0px 12px rgba(20, 14, 22, 0.9));
}

.logo {

    &__brand {
        width: 140px;
    }

    // &__social i{
    //     transition: 0.2s linear;
    //     color: var(--color5);

    //     &:hover{
    //         cursor:pointer;
    //     }
    // }
}
</style>