// Imports generales
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

// Importacion de Bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// Importación de Atropos
import 'atropos/css'

// Import MotionPlugin
import { MotionPlugin } from '@vueuse/motion'

createApp(App).use(store).use(MotionPlugin).mount('#app')
