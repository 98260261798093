<!-- TEMPLATE -->
<template>
    <div id="app">
        <main>

            <!-- NAVBAR -->
            <NavbarComponent />
            <!-- PARTICLE VIDEO -->
            <ParticleComponent class="particlesComp" />

            <!-- HERO -->
            <HeroComponent class="heroComp" />
            <!-- WISHLIST -->
            <WishlistComponent class="wishComp" />
            <!-- ABOUT -->
            <AboutComponent class="aboutComp" />

            <!-- DIVIDER STORY -->
            <DividerStoryComponent class="divComp" />
            <!-- DIVIDER STORY -->

            <!-- GALLERY -->
            <GalleryComponent class="galleryComp" />

            <!-- DIVIDER SQUAD -->
            <DividerSquadComponent class="divComp" />
            <!-- DIVIDER SQUAD -->

            <!-- CHARACTERS -->
            <CharactersComponent class="charactersComp" />
            <!-- FOOTER -->
            <FooterComponent class="footerComp" />
            
            <!-- <a class="d-none d-md-block" href="https://store.steampowered.com/app/2902170">
                <button id="click-me">
                    <i class="fa-brands fa-steam fs-2 text-white" aria-hidden="true"></i>
                </button>
            </a> -->

        </main>
    </div>
</template>

<!-- SCRIPT -->
<script>
import NavbarComponent from './components/NavbarComponent';
import ParticleComponent from './components/ParticleComponent';

import HeroComponent from './components/HeroComponent';
import WishlistComponent from './components/WishlistComponent';
import AboutComponent from './components/AboutComponent';

import DividerStoryComponent from './components/DividerStoryComponent.vue';

import GalleryComponent from './components/GalleryComponent';

import DividerSquadComponent from './components/DividerSquadComponent.vue';

import CharactersComponent from './components/CharactersComponent';
import FooterComponent from './components/FooterComponent';

export default {
    name: 'App',
    // props: {},
    data: function () {
        return {
            // isLoading: true
        };
    },
    mounted() {
        // setTimeout(() => {
        // this.isLoading = false;
        // }, 3000);
    },
    // computed: {},
    // methods: {},
    // watch: {},
    components: {
        NavbarComponent,
        ParticleComponent,
        HeroComponent,
        WishlistComponent,
        AboutComponent,
        DividerStoryComponent,
        DividerSquadComponent,
        GalleryComponent,
        CharactersComponent,
        FooterComponent,
    },
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<!-- STYLE -->
<style lang="scss">
// VARIABLES

:root {

    --color1: #33186B;
    --color1rgba: 51, 24, 107;
    --color2: #8C3ADA;
    --color2rgba: 140, 58, 218;
    --color3: #F2AFEF;
    --color3rgba: 242, 175, 239;
    --color4: #FFE6E6;
    --color4rgba: 255, 230, 230;
    --color5: #FFFFFF;
    --color5rgba: 255, 255, 255;

    --ff: "Bahnschrift", sans-serif;
    --ff2: "BelgianoSerif2", serif;
    --h1: 4rem;
    --h2: 3rem;
    --h3: 2.25rem;
    --h4: 1.5rem;
    --big: 1.25rem;
    --p: 1.1rem;
    --small: .75rem;
}

// FONTS

@font-face {
    font-family: Bahnschrift;
    src: url(assets/fonts/BAHNSCHRIFT.TTF);
}

@font-face {
    font-family: BelgianoSerif2;
    src: url(assets/fonts/BELGIANOSERIF2.TTF);
}

* {
    font-family: var(--ff);
    font-stretch: semi-condensed;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

// WEB CONFIG

html {
    overflow-x: hidden;
    overflow-y: scroll;
    // scroll-snap-type: y proximity;
    scroll-behavior: smooth;

}

img {
    -webkit-user-drag: none;
}

a {
    text-decoration: none !important;
}

h1 {
    font-size: var(--h1) !important;
}

h2 {
    font-size: var(--h2) !important;
}

h3 {
    font-size: var(--h3) !important;
}

h4 {
    font-size: var(--h4) !important;
}

p {
    font-size: var(--p) !important;
}

hr {
    border: solid 1px rgba(255, 255, 255, 0.2) !important;
}

// SECTIONS

.heroComp {
    z-index: -5;
}

.wishComp {
    position: relative;
    z-index: 10;
}

.aboutComp {
    position: relative;
    z-index: 5;
}

.divComp {
    position: relative;
    z-index: 10;
}

.particlesComp {
    z-index: 20;
}

.footerComp {
    position: relative;
    z-index: 10;
}

// TEXT CLASSES

.pinkneontxt {
    color: #ff91da;
    filter: drop-shadow(0px 0px 6px rgb(240, 16, 203));
}

.whiteneontxt {
    color: #FFFFFF;
    filter: drop-shadow(0px 0px 8px rgba(255, 230, 230, .4));
}

@media (min-width: 1400px) {
    html {
        font-size: 16px;
    }
}



// BOTON ESQUINA

// #click-me {
//     border-radius: (50%);
//     box-shadow: (0 0 15px 0 rgba(0, 0, 0, .3));
//     position: fixed;
//     right: 50px;
//     bottom: 40px;
//     height: 55px;
//     width: 55px;
//     line-height: 15px;
//     font-size: 20px;
//     padding: 10px;
//     border: 0;
//     color: #fff;
//     text-align: center;
//     background: #c54987;
//     cursor: pointer;
//     border: 1px solid #c54987;
//     z-index: 15;

//     &:hover {
//         font-size: 24px;
//         background: #2bbc73;
//         border: 1px solid #2bbc73;
//         color: #fff;
//     }

//     -moz-animation: shakeme 3s infinite;
//     -o-animation: shakeme 3s infinite;
//     -webkit-animation: shakeme 3s infinite;
//     animation: shakeme 3s infinite;
// }

// @keyframes shakeme {
//     0% {
//         scale: (1);
//     }

//     5% {
//         scale: (0.7);
//     }

//     10% {
//         scale: (1.5);
//     }

//     15% {
//         scale: (1.1);
//     }

//     20% {
//         scale: (1.4);
//     }

//     30% {
//         scale: (1.1);
//     }
// }

</style>
