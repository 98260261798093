<!-- TEMPLATE -->
<template>
    <!-- SECTION SQUAD CAROUSEL -->
    <section id="characters" class="characters">
        <div>

            <!-- GALLERY DESK -->
            <div id="carouselChar" class="carousel slide carousel-fade d-none d-sm-block">

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../assets/img/char/char1.jpg" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/img/char/char2.jpg" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/img/char/char3.jpg" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/img/char/char4.jpg" class="d-block w-100" alt="...">
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselChar" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden"></span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselChar" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden"></span>
                </button>
            </div>

            <!-- GALLERY MOBILE -->
            <div id="carouselCharMobile" class="carousel slide d-block d-sm-none">

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../assets/img/char/char1mobile.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/img/char/char2mobile.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/img/char/char3mobile.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/img/char/char4mobile.png" class="d-block w-100" alt="...">
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselCharMobile" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden"></span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselCharMobile" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden"></span>
                </button>
            </div>

        </div>
    </section>
</template>

<!-- SCRIPT -->
<script>
export default {
    name: 'CharactersComponent',
    // props: {},
    data: function () {
        return {}
    },
    // computed: {},
    // methods: {},
    // watch: {},
    components: {
    },
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<!-- STYLE -->
<style lang="scss" scoped>

$carousel-control-prev-icon-bg: url("../assets/img/misc/scroll_lefton.png");
$carousel-control-next-icon-bg: url("../assets/img/misc/scroll_righton.png");

.carousel-control-prev-icon{
    background-image: url("../assets/img/misc/scroll_lefton.png");
}

.carousel-control-next-icon{
    background-image: url("../assets/img/misc/scroll_righton.png");
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 10vw;
    height: 7vw;
}

@media (min-width: 768px){
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        width: 4vw;
        height: 3vw;
    }
}

</style>