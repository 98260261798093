<!-- TEMPLATE -->
<template>

    <!-- SECTION TRAILER + ABOUT -->
    <section id="gallery" class="gallery d-flex justify-content-center align-items-center">

        <div class="container py-5">

            <!-- ROW -->
            <div class="row mx-4">

                <!-- BOX | CAROUSEL -->
                <div v-motion
                    :initial="{ opacity: 0, x: -80, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                    :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                    :delay="320" class="gallery__box-carousel col-12 col-xl-8 col-xll-8 pb-5 pb-xl-0 my-auto">

                    <!-- GALLERY -->
                    <div id="carousel" class="carousel slide" data-bs-ride="true" data-bs-interval="3200">

                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carousel" data-bs-slide-to="0" class="active"
                                aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carousel" data-bs-slide-to="1"
                                aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carousel" data-bs-slide-to="2"
                                aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carousel" data-bs-slide-to="3"
                                aria-label="Slide 4"></button>
                            <!-- <button type="button" data-bs-target="#carousel" data-bs-slide-to="4"
                                aria-label="Slide 5"></button> -->
                            <!-- <button type="button" data-bs-target="#carousel" data-bs-slide-to="5"
                                aria-label="Slide 6"></button> -->
                            <!-- <button type="button" data-bs-target="#carousel" data-bs-slide-to="6"
                                aria-label="Slide 7"></button>
                            <button type="button" data-bs-target="#carousel" data-bs-slide-to="7"
                                aria-label="Slide 8"></button> -->
                        </div>

                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img id="img1" src="../assets/img/carousel/gif/car1.gif" class="d-block w-100"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img id="img2" src="../assets/img/carousel/gif/car2.gif" class="d-block w-100"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img id="img3" src="../assets/img/carousel/gif/car3.gif" class="d-block w-100"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img id="img4" src="../assets/img/carousel/gif/car4.gif" class="d-block w-100"
                                    alt="...">
                            </div>
                            <!-- <div class="carousel-item">
                                <img src="../assets/img/carousel/gif/car5.gif" class="d-block w-100" alt="...">
                            </div> -->
                            <!-- <div class="carousel-item">
                                <img src="../assets/img/carousel/gif/car6.gif" class="d-block w-100" alt="...">
                            </div> -->
                            <!-- <div class="carousel-item">
                                <img src="../assets/img/carousel/jpg/car7.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                                <img src="../assets/img/carousel/jpg/car8.gif" class="d-block w-100" alt="...">
                            </div> -->
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carousel"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden"></span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carousel"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden"></span>
                        </button>
                    </div>
                </div>

                <!-- BOX | STORY DESK -->
                <div v-motion
                    :initial="{ opacity: 0, x: 80, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                    :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                    :delay="0" class="gallery__box-story col-12 col-xl-4 col-xll-4 px-5 py-5 my-5 d-none d-xl-block">

                    <!-- TEXT -->
                    <h2 class="fw-semibold opacity-100 text-uppercase text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="80">Features</h2>
                    <hr class="text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="160">
                    <!-- <h4 class="fw-normal opacity-75 text-white py-2" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="240">The tower of Babel is burning the memories of mankind, covering the world in ashes.
                    </h4> -->
                    <h4 class="fw-normal opacity-75 text-white pt-2" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="320">• FAST PACED COMBO SYSTEM</h4>
                    <h4 class="fw-normal opacity-50 text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="320">• EQUIP & CUSTOMIZE YOUR UNITS</h4>
                    <h4 class="fw-normal opacity-75 text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="320">• TRANSFORM INTO A MAGICAL GIRL</h4>
                    <h4 class="fw-normal opacity-50 text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="320">• BOND WITH YOUR ALLIES</h4>
                </div>

                <!-- BOX | STORY MOBILE -->
                <div v-motion
                    :initial="{ opacity: 0, x: 80, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                    :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                    :delay="0" class="gallery__box-story col-12 col-xl-4 col-xll-4 px-4 py-4 d-block d-xl-none">

                    <!-- TEXT -->
                    <h2 class="fw-semibold opacity-100 text-uppercase text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="80">Story</h2>
                    <hr class="text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="160">
                    <!-- <h4 class="fw-normal opacity-75 text-white py-2" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="240">The tower of Babel is burning the memories of mankind, covering the world in ashes.
                    </h4> -->
                    <h5 class="fw-normal opacity-75 text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="320">• FAST PACED COMBO SYSTEM</h5>
                    <h5 class="fw-normal opacity-50 text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="320">• EQUIP & CUSTOMIZE YOUR UNITS</h5>
                    <h5 class="fw-normal opacity-75 text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="320">• TRANSFORM INTO A MAGICAL GIRL</h5>
                    <h5 class="fw-normal opacity-50 text-white" v-motion
                        :initial="{ opacity: 0, x: 100, transition: { duration: 400, type: 'keyframes', ease: 'easeIn' } }"
                        :visibleOnce="{ opacity: 1, x: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="320">• BOND WITH YOUR ALLIES</h5>
                </div>

            </div>

        </div>
    </section>

</template>

<!-- SCRIPT -->
<script>

export default {
    name: 'GalleryComponent',
    // props: {},
    data: function () {
        //     carouselImg = () => {
        //     const currentImg = document.getElementsById('img1');

        //     if (currentImg == 'img1') {
        //         document.getElementsById('img1').classList.remove('opacity-50')
        //         document.getElementsById('img1').classList.add('opacity-75')
        //     }
        // }
    },
    computed: {
    },
    methods: {
    },
    // watch: {},
    components: {
    },
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<!-- STYLE -->
<style lang="scss" scoped>
$carousel-control-prev-icon-bg: url("../assets/img/misc/scroll_lefton.png");
$carousel-control-next-icon-bg: url("../assets/img/misc/scroll_righton.png");

* {
    font-stretch: condensed;
}

.gallery {
    background: url(../assets/img/bg/jpg/bg10.jpg) rgba(29, 11, 39, 0.8);
    background-size: cover;
    height: 100%;
    background-blend-mode: multiply;
    background-position: center;

    &__box-story {

        background: linear-gradient(180deg, rgba(16, 51, 60, 0.7) 0%, rgba(11, 56, 58, 0.9) 100%);
        border-radius: 1rem;
        border: solid 1px rgba(255, 255, 255, 0.4);
        filter: contrast(140%);

        & p {
            margin-block-end: 0px;
            letter-spacing: 0.2px;
            line-height: 1.2rem;
        }
    }
}

.carousel-inner {
    border-radius: 1rem;
}

.carousel-control-prev-icon {
    background-image: url("../assets/img/misc/scroll_lefton.png");
    filter: drop-shadow(0px 2px 0px rgba(var(--color1rgba), 0.6));
}

.carousel-control-next-icon {
    background-image: url("../assets/img/misc/scroll_righton.png");
    filter: drop-shadow(0px 2px 0px rgba(var(--color1rgba), 0.6));
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 2.5vw;
    height: 2.5vw;
}

@media (min-width: 1440px) {
    .gallery {
        height: 100vh;
    }

}
</style>
