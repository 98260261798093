<!-- TEMPLATE -->
<template>
    <video muted autoplay loop playsinline disablePictureInPicture>
        <source src="../assets/video/particles.mp4" type="video/mp4">
    </video>
</template>

<!-- SCRIPT -->
<script>
export default {
    name: 'ParticleComponent',
    // props: {},
    data: function(){
        return{}
    },
    // computed: {},
    // methods: {},
    // watch: {},
    // components: {},
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<!-- STYLE -->
<style scoped>

video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    mix-blend-mode: screen;
    z-index: 10;
    pointer-events: none
}

</style>