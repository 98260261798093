<!-- TEMPLATE -->
<template>
    <!-- SECTION HEADER -->
    <section id="header" class="header d-flex justify-content-center align-items-end">

        <!-- HEADER -->
        <div class="header__info container">
            <!-- BOX | FORM -->
            <div class="header__box col-12 col-md-6 col-lg-4 py-5 mb-5 text-center">
                <!-- LOGO -->
                <img class="header__logo img-fluid px-2" src="../assets/img/misc/kernel_logo.png"
                    alt="kernel-hearts-logo" v-motion :initial="{ opacity: 0, y: -80 }"
                    :visibleOnce="{ opacity: 1, y: 0, transition: { duration: 1400, type: 'keyframes', ease: 'easeOut' } }"
                    :delay="200" >
                <!-- TITLE -->
                <h4 class="header__title pt-4 mt-3 pb-2 fs-1 fw-light text-white" v-motion :initial="{ opacity: 0, y: 20 }"
                    :visibleOnce="{ opacity: 1, y: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                    :delay="1000" >Sign up for our <span
                        class="fw-bold glowloop">free demo!</span></h4>

                <!-- FORM Y SOCIALS -->
                <div class="">
                    <!-- FORM -->
                    <form action="https://ephemeragames.us21.list-manage.com/subscribe/post?u=5886a6fb784fd6ab0a563334d&amp;id=6d71254476&amp;f_id=00769fe6f0"
                        method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" 
                        class="validate clickable form mx-4" v-motion :initial="{ opacity: 0, y: 30 }"
                        :visibleOnce="{ opacity: 1, y: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="1200" >
                        <div class="form__container input-group">
                            <input name="EMAIL" type="email" id="mce-EMAIL" required="" value="" class="required email form-control fw-light" placeholder="sample@gmail.com" aria-label="sample@gmail.com">
                            <button class="btn btn-primary fw-medium px-4" type="submit" name="subscribe" id="mc-embedded-subscribe" value="Subscribe">Sign up</button>
                        </div>
                        <div id="mce-responses" class="clear foot">
                            <div class="response" id="mce-error-response" style="display: none;"></div>
                            <div class="response" id="mce-success-response" style="display: none;"></div>
                        </div>
                    </form>
                    <!-- LOGOS SOCIALS -->
                    <div class="header__socials d-flex justify-content-center align-items-center row pt-4 mt-2" v-motion :initial="{ opacity: 0, y: 30 }"
                        :visibleOnce="{ opacity: 1, y: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="1200">
                        <div class="social__icon col-3 mx-0 px-0"
                        v-motion :initial="{ opacity: 0, y: 10 }"
                        :visibleOnce="{ opacity: 1, y: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="1400">
                            <a href="https://store.steampowered.com/app/2902170/Kernel_Hearts/">
                                <h1 class="fa-brands fa-steam text-white clickable"></h1>
                            </a>
                        </div>
                        <div class="social__icon col-3 mx-0 px-0"
                        v-motion :initial="{ opacity: 0, y: 10 }"
                        :visibleOnce="{ opacity: 1, y: 0, transition: { duration: 1200, type: 'keyframes', ease: 'easeOut' } }"
                        :delay="1600">
                            <a href="https://discord.gg/e8NvKhXqrU">
                                <h1 class="fa-brands fa-discord text-white clickable"></h1>
                            </a>
                        </div>
                    </div>
                </div>


            </div>

        </div>

        <!-- ATROPOS -->
        <div class="atropos__container">
            <atropos-component class="my-atropos" active-offset="55" always-active="true" rotate-x-max="1"
                rotate-y-max="1" highlight="false">
                <img class="atropos__img1" src="../assets/img/atropos/webp/BG.webp" alt="BG" data-atropos-offset="-1.5">
                <img class="atropos__img2" src="../assets/img/atropos/webp/Spika.webp" alt="Spika"
                    data-atropos-offset="-1">
                <img class="atropos__img3" src="../assets/img/atropos/webp/Star.webp" alt="Star"
                    data-atropos-offset="-0.9">
                <img class="atropos__img4" src="../assets/img/atropos/webp/Laika.webp" alt="Laika"
                    data-atropos-offset="-0.5">
                <img class="atropos__img5" src="../assets/img/atropos/webp/Fernet.webp" alt="Fernet"
                    data-atropos-offset="0.5">
                <img class="atropos__img6" src="../assets/img/atropos/webp/Tana.webp" alt="Tana"
                    data-atropos-offset="1">
                <img class="atropos__img7" src="../assets/img/atropos/webp/FG.webp" alt="FG" data-atropos-offset="1.5">
                <img class="atropos__img8" src="../assets/img/atropos/webp/FG.webp" data-atropos-offset="2">
            </atropos-component>
        </div>

    </section>
</template>

<!-- SCRIPT -->
<script>
import AtroposComponent from 'atropos/element';
// Register Atropos component
customElements.define('atropos-component', AtroposComponent);

export default {
    name: 'HeroComponent',
    // props: {},
    data: function () {
        return {}
    },
    // computed: {},
    // methods: {},
    // watch: {},
    // components: {},
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<!-- STYLE -->
<style lang="scss" scoped>

.header {

    width: 100%;
    height: 100vh;
    background: url("../assets/img/bg/jpg/bghero.jpg");
    background-position: 46% 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    &__info {
        position: relative;
        pointer-events:none;
    }
    &__logo {
        filter: drop-shadow(0px 2px 1px rgba(var(--color1rgba), 1));
    }
    &__title {
        // -webkit-font-smoothing: antialiased;
        // -moz-osx-font-smoothing: grayscale;
        filter: drop-shadow(0px 2px 1px rgba(var(--color1rgba), 1));
    }
    &__socials {
        filter: drop-shadow(0px 3px 2px rgba(var(--color1rgba), .6));
    }
    &__form{
        pointer-events: auto;
    }

}

.clickable{
    pointer-events: auto;
}

.social{

    &__icon{
        transition: 0.3s linear;
        color: var(--color5);

        &:hover{
            cursor:pointer;
            text-shadow: rgba(var(--color5rgba), .6) 0px 0px 8px;
        }
    }
}

.form {

    filter: drop-shadow(0px 3px 2px rgba(var(--color1rgba), .6));

    &__container input {
        border: 0px;
        padding: 8px 16px;
        border-radius: 12px;
    }
    & button {
        border: 0px;
        border-radius: 12px;
        background-color: var(--color2);
        transition: 0.3s;
    }
    & button:hover {
        background-color: #641fa5;
    }
}

.invisiblediv{
    pointer-events:none
}

.atropos {

    &__container {
        display: none;
    }

}

.glowloop{
    animation: glowing 4000ms infinite;
}

@keyframes glowing {
  0% { filter: drop-shadow(0px 0px 18px rgba(255, 255, 255, .4)); }
  50% { filter: drop-shadow(0px 0px 18px rgba(255, 255, 255, 1)); }
  100% { filter: drop-shadow(0px 0px 18px rgba(255, 255, 255, .4)); }
}

@media (min-width: 1440px) {

    .header {
        &__info {
            position: absolute;
            top: 50%;
            left: 15%;
            z-index: 2;
            width: 90%;
        }
    }

    .my-atropos {
        display: flex;
        justify-content: start;
        align-items: start;
        width: 100vw;
    }

    .atropos {

        &__container {
            display: inline;
        }

        &__img1 {
            position: absolute;
            z-index: -3;
            height: 100vh;
            // width: 100%;
        }

        &__img2 {
            position: absolute;
            z-index: -2;
            height: 100vh;
            // width: 100%;
        }

        &__img3 {
            position: absolute;
            z-index: -1;
            height: 100vh;
            // width: 100%;
        }

        &__img4 {
            position: absolute;
            z-index: 0;
            height: 100vh;
            // width: 100%;
        }

        &__img5 {
            position: absolute;
            z-index: 1;
            height: 100vh;
            // width: 100%;
        }

        &__img6 {
            position: absolute;
            z-index: 2;
            height: 100vh;
            // width: 100%;
        }

        &__img7 {
            position: absolute;
            z-index: 3;
            height: 100vh;
            // width: 100%;
        }

        &__img8 {
            opacity: 0;
            z-index: 4;
            height: 100vh;
            // width: 100%;
        }
    }
}

@media (min-width: 1920px) {

    .atropos {

        &__img1 {
            height: auto;
            width: 100vw;
        }

        &__img2 {
            height: auto;
            width: 100vw;

        }

        &__img3 {
            height: auto;
            width: 100vw;

        }

        &__img4 {
            height: auto;
            width: 100vw;

        }

        &__img5 {
            height: auto;
            width: 100vw;

        }

        &__img6 {
            height: auto;
            width: 100vw;

        }

        &__img7 {
            height: auto;
            width: 100vw;

        }

        &__img8 {
            height: auto;
            width: 100vw;

        }
    }
}


@media (min-width: 2560px) {
    .header {
        &__info {
            top: 55%;
            left: 25%;
            z-index: 2;
            width: 90%;
        }
    }
}
</style>
