<!-- TEMPLATE -->
<template>

    <!-- SECTION TRAILER + ABOUT -->
    <section id="about" class="about d-flex justify-content-center align-items-center">

        <div class="container py-5">

            <!-- ROW -->
            <div class="row mx-4">

                <!-- BOX | ABOUT DESK -->
                <div class="about__box-about col-12 col-xl-4 col-xll-4 px-5 py-5 my-5 d-none d-xl-block"
                v-motion :initial="{ opacity: 0, x: -80, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="0" 
                >

                    <!-- TEXT -->
                    <h2 class="fw-semibold opacity-100 text-uppercase text-white" 
                    v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="80"
                    >
                    About</h2>

                    <hr class="text-white" 
                    v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="160"
                    >

                    <h4 class="fw-normal opacity-75 text-white py-2" 
                    v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="240"
                    >
                    Gather your allies. Eliminate Angels. Transform into a Magical
                        Girl. Slay god.</h4>
                    <p class="fw-lighter opacity-50  text-white" 
                    v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="320"
                    >
                    Kernel Hearts is a unique blend of action RPG and roguelike elements with multiplayer co-op. As part of the M.A.H.O.U Unit, you are tasked with climbing the Tower of Babel, eliminating the angelic creatures in your path as you dethrone God and save a world drowning in ashes.</p>
                </div>


                <!-- BOX | VIDEO -->
                <div class="about__box-video col-12 col-xl-8 col-xll-8 pb-5 pb-xl-0 my-auto"
                v-motion :initial="{ opacity: 0, x: 80, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="320" 
                >
                    <!-- VIDEO -->
                    <div class="ratio ratio-16x9">
                        <iframe class="embed-responsive-item video-radius"
                            src="https://www.youtube.com/embed/8mdQRFmarws?si=Sri7RX8okeT7-XdT?autoplay=0&amp;rel=0&amp;controls=1&amp;frameborder=&quot;0&quot;&amp;loop=0"
                            title="Kernel Hearts - Official Game Trailer" allowfullscreen></iframe>
                    </div>
                </div>

                <!-- BOX | ABOUT MOBILE -->
                <div  class="about__box-about col-12 col-xl-5 col-xll-4 px-4 py-4 d-block d-xl-none"
                v-motion :initial="{ opacity: 0, x: -80, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="0"
                >

                    <!-- TEXT -->
                    <h2 class="fw-semibold opacity-100 text-uppercase text-white"
                    v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="80"
                    >
                    About</h2>

                    <hr class="text-white"
                    v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="160"
                    >

                    <h4 class="fw-normal opacity-75 text-white py-2"
                    v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="240"
                    >
                    Gather your allies. Eliminate Angels. Transform into a Magical
                        Girl. Slay god.</h4>
                    <p class="fw-lighter opacity-50 text-white "
                    v-motion :initial="{ opacity: 0, x: -100, transition:{duration: 400, type: 'keyframes', ease: 'easeIn'}}" :visibleOnce="{ opacity: 1, x: 0, transition:{duration: 1200, type: 'keyframes', ease: 'easeOut'}}" :delay="320"
                    >
                    As part of the M.A.H.O.U Unit, you and up to four
                        players must defeat the
                        angels that protect the floors of the Tower of Babel and its shifting, sentient layout– all in
                        the hope of saving what's left of mankind.
                        After each run, the M.A.H.O.U's memory will return to it's backup, going back to the city of
                        Babel on the outskirts of the tower, where you get to know the cosmopolitan refuge of humanity's
                        last survivors. Here the players will have the chance to socialize and get to know a diverse
                        cast of NPC's before heading back into action.</p>
                </div>

            </div>

        </div>
    </section>

</template>

<!-- SCRIPT -->
<script>

export default {
    name: 'AboutComponent',
    // props: {},
    data: function () {
        return {}
    },
    // computed: {},
    // methods: {},
    // watch: {},
    components: {
    },
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<!-- STYLE -->
<style lang="scss" scoped>

*{
    font-stretch: condensed;
}

.about {
    background: url(../assets/img/bg/jpg/bg8.jpg) rgb(224, 205, 201);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: soft-light;
}

.video-radius {
    border-radius: 1rem;
}

.about {

    &__box-video{
        filter: drop-shadow(0px 0px 80px rgba(255, 255, 255, 0.6));
    }
    &__box-about {

        background: linear-gradient(180deg, rgba(16, 51, 60, 0.7) 0%, rgba(11, 56, 58, 0.9) 100%);
        border-radius: 1rem;
        border: solid 1px rgba(255, 255, 255, 0.4);
        filter: contrast(140%);

        & p{
            margin-block-end: 0px;
            letter-spacing: 0.2px;
            line-height: 1.2rem;
        }
    }

}

@media (min-width: 1440px) {
    .about {
        height: 100vh;
    }
}

</style>